<template>
	<div style="height: 100%;">
		<main-table tool>
			<el-form slot="header" :inline="true" :model="params" :size="theme.size">
				<el-form-item>
					<el-button type="primary" @click="create_drawer = true">新增地址</el-button>
				</el-form-item>
			</el-form>
			<el-table slot="table" ref="wallet_table" class="bill scroll-wrapper absolute" height="100%" :data="addresses" :size="theme.size">
				<el-table-column prop="shop.name" label="所属门店" width="100" v-if="!admin_user.shop_id"></el-table-column>
				<el-table-column prop="attn" label="联系人" width="100"></el-table-column>
				<el-table-column prop="detail" label="性别" width="60">
					<template slot-scope="scope">
					<span v-if="scope.row.sex==0">未知</span>
					<span v-if="scope.row.sex==1">女士</span>
					<span v-if="scope.row.sex==2">男士</span>
					</template>
				</el-table-column>
				<el-table-column prop="mobile" label="联系电话" width="120"></el-table-column>
				<el-table-column prop="full_regions" label="地址地区">
					<template slot-scope="scope">
						<sapn v-for="region in scope.row.regions" :key="region.id">{{region.name}}</sapn><br>{{scope.row.address}}
					</template>
				</el-table-column>
				<el-table-column prop="detail" label="详细地址"></el-table-column>
				<el-table-column prop="detail" label="地址类型" width="100">
					<template slot-scope="scope">{{types[scope.row.type]}}</template>
				</el-table-column>
				<el-table-column prop="updated_at" label="更新时间" width="180" v-if="theme.width >= 1080"></el-table-column>
				<el-table-column prop="created_at" label="创建时间" width="180" v-if="theme.width >= 1080"></el-table-column>
				<el-table-column prop="created_at" label="更多操作" width="100">
					<template slot-scope="scope">
						<el-button type="text" @click="onEdit(scope.row)" :disabled="!$utils.update('addresses')">编辑</el-button>
						<el-button type="text" @click="onDelete(scope.row)" :disabled="!$utils.delete('addresses')">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
				slot="footer"
				:layout="$config.PAGE_LAYOUT"
				:page-sizes="$config.PAHE_SIZES"
				:page-size="params.perPage"
				:current-page="params.page"
				:total="params.total"
				@size-change="(v) => { getAddresses({ ...params, page: 1, perPage: v }) }"
				@current-change="(v) => { getAddresses({ ...params, page: v }) }"
				background>
			</el-pagination>
		</main-table>
		<el-drawer title="新增地址" size="580px" :visible.sync="create_drawer" @opened="initMap" @closed="resetForm('create_form')">
			<div style="padding: 0 15px;">
				<el-form ref="create_form" label-width="80px" :model="address" :rules="create_rules" :size="theme.size" status-icon>
					<el-form-item label="联系人" prop="attn">
						<el-input placeholder="请输入联系人" autocomplete="off" v-model="address.attn" maxlength="8" show-word-limit clearable>
							<el-select slot="prepend" style="width: 80px;" placeholder="请选择" v-model="address.sex">
								<el-option label="未知" :value="0"></el-option>
								<el-option label="女士" :value="1"></el-option>
								<el-option label="男士" :value="2"></el-option>
							</el-select>
						</el-input>
					</el-form-item>
					<el-form-item label="联系电话" prop="mobile">
						<el-input placeholder="请输入号码" autocomplete="off" v-model="address.mobile" maxlength="18" show-word-limit clearable>
							<el-select slot="prepend" style="width: 120px;" placeholder="请选择" v-model="address.tel_type" @change="address.code = ''">
								<el-option label="普通座机号" :value="1"></el-option>
								<el-option label="企业座机号" :value="2"></el-option>
								<el-option label="手机号码" :value="3"></el-option>
							</el-select>
						</el-input>
					</el-form-item>
					<el-form-item label="所属地区" prop="regions">
						<el-cascader style="width: 100%;" placeholder="请选择所属地区" v-model="address.regions" :options="tree_regions" :props="{expandTrigger: 'hover', value: 'id', label: 'name'}" filterable clearable></el-cascader>
					</el-form-item>
					<el-form-item label="详细地址" prop="detail">
						<el-input style="width: 380px; margin-right: 10px;" placeholder="请输入详细地址。不要重复填写地区" autocomplete="off" v-model="address.detail" maxlength="18" show-word-limit clearable></el-input>
						<el-button>搜索地图</el-button>
					</el-form-item>
					<el-form-item label-width="0">
						<div ref="mapContainer" :style="'wdith: 100%; height: '+(theme.height - 340)+'px'"></div>
					</el-form-item>
					<el-form-item label-width="0">
						<el-button type="primary" @click="submitForm('create_form')">确 定</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-drawer>
	</div>	
</template>

<style>

</style>
<script>
	import { mapState } from 'vuex';
	import mainTable from '@/components/main-table';
	
	export default {
		components: {
			mainTable
		},
		computed: {
			...mapState(['theme', 'admin_user', 'regions']),
			tree_regions () {
				return this.$utils.buildTree(this.regions);
			}
		},
		methods: {
			initMap () {
				this.map = new window.AMap.Map(this.$refs.mapContainer, {center: [114.05956, 22.54286], zoom: 10.8});
				this.map.on('hotspotclick',this.getLatLng);
			},
			getLatLng (e) {
				console.log(e)
				var placeSearch = new window.AMap.PlaceSearch()
				placeSearch.getDetails(e.id, (status, result) => {
					console.log(result)
				})
				var geocoder = new window.AMap.Geocoder({
					city:'全国'
				});
				var lnglat = [e.lnglat.lng, e.lnglat.lat];
				geocoder.getAddress(lnglat, (status, result) => {
					if (status === "complete" && result.info === "OK") {
						// that.form.city=(result.regeocode.addressComponent.city).replace("市","");
						console.log(result)
						// that.form.projectAddress=result.regeocode.formattedAddress
					}
				})
			},
			resetForm (ref) {
				this.create_drawer = false;
				this.$refs[ref].resetFields();
				this.address = {};
			},
			submitForm (ref) {
				this.$refs[ref].validate((valid) => {
					if (!valid) return this.$message.error("表单提交有误！");
					this.submitAddresses(this.address);
				})
			},
			onEdit (r) {
				this.address = { ...r, shop: this.$route.query.no, regions: r.regions.map(i => { return i.id }) }
				this.create_drawer = true;
			},
			onDelete (r) {
				this.$confirm('确定要删除【'+r.detail+'】地址吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_ADDRESSES, {params: {action: 'delete', id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.$message({
						type: 'success',
						duration: 1000,
						message: msg,
						onClose: () => {
							this.getAddresses(this.params);
						}
					});
				})
			},
			async submitAddresses (data) {
				// r.regions = [20, 233, 4047, 4048];
				const res = await this.$http.post(this.$api.URI_ADDRESSES, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose: () => {
						if (this.create_drawer) {
							this.create_drawer = false;
							this.getAddresses(this.params);
						}
					}
				});
			},
			async getAddresses (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_ADDRESSES, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.addresses = result.data;
				this.types = result.types;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				map: null,
				types: [],
				addresses: [],
				create_drawer: false,
				create_rules: {
					attn: [{ required: true, message: '必须填写联系人', trigger: 'blur' }],
					mobile: [{ required: true, message: '必须填写联系号码', trigger: 'blur' }],
					regions: [{ required: true, message: '必须选择区域', trigger: 'blur' }, { type: 'array', message: '必须为联级数组', trigger: 'blur' }],
					detail: [{ required: true, message: '必须填写详细地址', trigger: 'blur' }]
				},
				address: {
					tel_type: 1
				},
				params: {
					perPage: 10,
				}
			}
		},
		async created () {
			let { query } = this.$route;
			window.AMapLoader.load({
				"key": "08aa15adc7d41a9fe82ecb2aa53879e4",              // 申请好的Web端开发者Key，首次调用 load 时必填
				"version": "2.0",   // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
				"plugins": ['AMap.PolygonEditor', 'AMap.PlaceSearch', 'AMap.AutoComplete'],           // 需要使用的的插件列表，如比例尺'AMap.Scale'等
				"AMapUI": {             // 是否加载 AMapUI，缺省不加载
					"version": '1.1',   // AMapUI 版本
					"plugins":['overlay/SimpleMarker'],       // 需要加载的 AMapUI ui插件
				},
				"Loca":{                // 是否加载 Loca， 缺省不加载
					"version": '2.0'  // Loca 版本
				}
			});
			this.address = {...this.address, shop: query.no}
			this.getAddresses({...this.params, shop: query.no}, true);
		}
	};
</script>